/* Handles fetching a list of autocomplete possibilities and displaying matching ones
 * when you type into an input field. */
import { clearEl, makeEl, escape } from './dom';

class InputAutocomplete {
    // the element should be a container which contains the input and the .dropdown-menu menu
    constructor(element) {
        this.inputElement = element.querySelector('input');
        this.dropdownItemsElement = element.querySelector('.dropdown-content');
        this.autocompleteItems = null;
        this.autocompleteUrl = element.dataset.autocompleteSource;
    }

    attach() {
        this.inputElement.addEventListener('keyup', e => {
            this._search(this.inputElement.value);
        });

        this.inputElement.addEventListener('focusin', e => {
            if (this.inputElement.value) {
                this._search(this.inputElement.value);
            }
        });

        this.inputElement.addEventListener('focusout', e => {
            setTimeout(() => this.dropdownItemsElement.parentElement.style = '', 100);
        });
    }

    _search(searchString) {
        const tags = searchString.toLowerCase().split(',');
        const lastTag = tags[tags.length - 1].trim();

        if (lastTag === '') {
            return;
        }

        this._loadAutocompleteItems().then(items => {
            // Find matching results
            const matchingItems = [];
            for (const item of items) {
                if (item.toLowerCase().indexOf(lastTag) === 0) {
                    matchingItems.push(item);
                }
            }

            // Load them into the dropdown and make sure it's displayed.
            clearEl(this.dropdownItemsElement);

            for (const result of matchingItems) {
                const suggestionEl = makeEl(`<a class="dropdown-item" href="#">${escape(result)}</a>`);

                suggestionEl.addEventListener('click', e => {
                    e.preventDefault();
                    // Add the stupid tag?
                    this.inputElement.dispatchEvent(
                        new CustomEvent('tagadded', { details: { tag: result } })
                    );
                    // Clear the search string from the input
                    this.inputElement.value = this.inputElement.value.substring(0, this.inputElement.value.lastIndexOf(lastTag));
                    this.inputElement.value += result;
                    this.dropdownItemsElement.style = '';
                });

                this.dropdownItemsElement.appendChild(suggestionEl);
            }

            this.dropdownItemsElement.parentElement.style = 'display: inline-block !important;';
        });
    }

    _loadAutocompleteItems() {
        if (this.autocompleteItems !== null) {
            return Promise.resolve(this.autocompleteItems);
        }

        return fetch(this.autocompleteUrl)
                .then(r => r.json())
                .then(j => {
                    this.autocompleteItems = j;
                    return j;
                });
    }
}

function setupInputAutocomplete() {
    for (const element of document.querySelectorAll('.js-input-autocomplete')) {
        new InputAutocomplete(element)
            .attach();
    }
}

export { setupInputAutocomplete };