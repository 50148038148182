/* Handles calling other parts of the JavaScript code when the DOM is ready. */
import { setupUjs } from "./ujs";
import { setupSorting } from "./sort";
import { setupTagsInput } from './tag_input';
import { setupPhotoGallery } from "./gallery";
import { setupFileUploads } from "./file-upload";
import { setupAdminCollapse } from "./admin-tools";
import { setupInputAutocomplete } from "./input_autocomplete";

function whenReady() {
    setupUjs();
    setupSorting();
    setupTagsInput();
    setupPhotoGallery();
    setupFileUploads();
    setupAdminCollapse();
    setupInputAutocomplete();
}

if (document.readyState !== 'loading') {
    whenReady();
} else {
    document.addEventListener('DOMContentLoaded', whenReady);
}
