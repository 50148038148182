/* Handles setting up sorting. */
import { Sortable } from 'sortablejs';

// This handles sorting the image grid for Aggregates.
function setupAggregateSorting() {
    const element = document.querySelector('#js-aggregate-reorder');

    if (!element) {
        return;
    }

    let sorting = false;

    element.addEventListener('click', e => {
        const grid = document.querySelector('.image-grid');
        if (!grid) {
            return;
        }

        sorting = !sorting;

        if (sorting) {
            element.innerText  = 'Save Category Order';
            grid.classList.add('grid--is-sorting');
            Sortable.create(grid,
                {
                    sort: true,
                    onEnd: function(evt) {
                        const oldIndex = evt.oldDraggableIndex;
                        const newIndex = evt.newDraggableIndex;

                        if (oldIndex !== newIndex) {
                            // Re-number all the children.
                            let i = 0;
                            for (const child of grid.querySelectorAll('.image-container')) {
                                child.dataset.ordinal = String(i);
                                i++;
                            }
                        }
                    }
                }
            );
        } else {
            element.innerText = 'Re-order Category (Saving...)';
            element.disabled = true;
            element.classList.add('is-loading');
            grid.classList.remove('grid--is-sorting');
            const payload = {};

            for (const child of grid.querySelectorAll('.image-container')) {
                payload[child.dataset.id] = String(child.dataset.ordinal);
            }

            const csrfToken = document.querySelector('meta[name=csrf-token]').getAttribute('content');

            fetch(window.location, {
                method: 'put',
                body: JSON.stringify({ order: payload }),
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': csrfToken
                }
            }).then(res => {
                element.disabled = false;
                element.innerText = 'Re-order Category (Saved!)';
            });
        }
    });
}

function setupSorting() {
    const elements = document.querySelectorAll('.sortable');

    for (const element of elements) {
        Sortable.create(element,
            {
                sort: true,
                onEnd: function(evt) {
                    const oldIndex = evt.oldDraggableIndex;
                    const newIndex = evt.newDraggableIndex;

                    if (oldIndex !== newIndex) {
                        // Re-number all the children.
                        let i = 0;

                        for (const child of element.children) {
                            child.querySelector('input[type=hidden]').setAttribute('value', String(i));
                            i++;
                        }
                    }
                }
            }
        );
    }

    setupAggregateSorting();
}

export { setupSorting };
